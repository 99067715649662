const { GrowthBook } = require('@growthbook/growthbook');
const v4 = require('uuid').v4;
const { jwtDecode } = require('jwt-decode');
const Cookies = require('js-cookie')

function readMeta(name, attribute) {
  const tag = document.querySelector(`meta[name=${name}]`);
  if (!tag) {
    return null;
  }
  if (attribute) {
    return tag.getAttribute(attribute);
  }
  return tag.getAttribute('content');
}

function readDomainCookie(name) {
  const d = readMeta('cookie_domain');
  if (d) {
    return Cookies.get(name, { domain: d });
  }
  return Cookies.get(name);
}

function writeDomainCookie(name, value, options = {}) {
  const d = readMeta('cookie_domain');
  let optionsObject = options;
  if (d) {
    optionsObject = Object.assign({ domain: d }, options);
  }
  if (Object.keys(optionsObject).length) {
    Cookies.set(name, value, optionsObject);
  } else {
    Cookies.set(name, value);
  }
}

function currentJwt() {
  return readDomainCookie('jwt');
}

function jwtData() {
  const token = currentJwt();
  if (!token) throw new Error('No login token');
  return jwtDecode(token);
}

function getUserId() {
  let sub;
  try {
    sub = jwtData().sub;
  } catch (e) {
    return null;
  }
  if (!sub) return null;

  return sub;
}

let gb = null;

const generateCookie = () => {
  const COOKIE_NAME = 'gbuuid';
  const COOKIE_DAYS = 400;

  const existingId = readDomainCookie(COOKIE_NAME);
  if (existingId) return existingId;

  const newCookieId = v4();
  writeDomainCookie(COOKIE_NAME, newCookieId, { expires: COOKIE_DAYS });
  return newCookieId;
};

const getGrowthbook = () => {
  return gb;
};

const resetGrowthbook = () => {
  gb = null;
};

async function initGrowthbook() {
  if (gb) return gb; // Return the existing instance if already initialized

  const gbClientKey = readMeta('growthbook-client-key');
  const gbDevMode = readMeta('growthbook-dev-mode').toLowerCase() === 'true'

  if (!gbClientKey) {
    console.error('GrowthBook client key missing');
    return null;
  }

  const userId = getUserId();
  const gbuuid = generateCookie();

  try {

    gb = new GrowthBook({
      clientKey: gbClientKey,
      enableDevMode: gbDevMode,
      attributes: { userId, gbuuid, country: 'US' },
      trackingCallback: (experiment, result) => {
        analytics.track("Experiment Viewed", {
          experimentId: experiment.key,
          variationId: result.key,
          gbuuid: gbuuid,
        });
      }
    });

    await gb.init({ streaming: true });

    return gb;

  } catch (e) {
    console.error('Error initializing GrowthBook:', e);
    return null;
  }
}

module.exports = {
  getGrowthbook,
  initGrowthbook,
  resetGrowthbook
};
